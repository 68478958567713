import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import noSlotsError from "../../assets/images/noSlotsError.png";
import RequestMentorPopupButton from "../CancelAndReschedule/RequestMentorPopupButton"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Poppins",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  doneTxt: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#554E4E",
    textAlign: "center",
    marginTop: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  btnContainer: {
    border: "1px solid #25D366",
    color: "#25D366",
    background: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "center",
    marginTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    "&:hover": {
      background: "#FFFFFF",
      color: "#25D366",
      border: "1px solid #25D366",
    },
  },
  reselectTxt: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#525252",
    textAlign: "center",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
}));

const SuccessView = ({ error,freeSlotError,lastClassPassed,setShowAlternateMentorModal,autoRescheduleGTM }) => {
  const classes = useStyles();
  return (
    <div>
      {error ? (
        <div className={classes.root}>
          <img src={noSlotsError} alt="error" />
          <div className={classes.doneTxt}>
            Looks like the teacher does not have any free slots available.
          </div>
          <div className={classes.reselectTxt} style={{ marginTop: "40px",marginBottom:"20px" }}>
          You can request a substitute teacher at any convenient timing
          </div>
          <RequestMentorPopupButton
            setShowAlternateMentorModal={setShowAlternateMentorModal}
            autoRescheduleGTM={autoRescheduleGTM}
          />
        </div>
      ) :freeSlotError ? (
        <div className={classes.root}>
          <img src={"https://cy-assets.s3.ap-south-1.amazonaws.com/student-portal/icons/no+slots.png"} alt="error" />
          <div className={classes.doneTxt}>
            Looks like the teacher does not have any free slots available
          </div>
          <div className={classes.reselectTxt} style={{ marginTop: "40px",marginBottom:"20px" }}>
          You can request a substitute teacher at any convenient timing
          </div>
          <RequestMentorPopupButton
            setShowAlternateMentorModal={setShowAlternateMentorModal}
            autoRescheduleGTM={autoRescheduleGTM}
          />
        </div>
      ):lastClassPassed?(
        <div className={classes.root}>
          <img src={"https://cy-assets.s3.ap-south-1.amazonaws.com/student-portal/icons/no+class+balance.png"} alt="error" />
          <div className={classes.doneTxt} style={{color:"red"}}>
          You don't have any classes balance left
          </div>
        </div>
      ):(
        <div className={classes.root}>
          <img src={noSlotsError} alt="error" />
          <div className={classes.doneTxt}>
            Rescheduling is not available for our group classes.
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessView;
