export const getSlotsCountByDay = (day, slotsData) => {
    if (slotsData.hasOwnProperty(day)) {
      const slots = slotsData[day];
      const totalSlotsCount = slots.length;
      const freeSlotsCount = slots.filter(
        (slot) => slot.free === 1
      ).length;
      return {
        freeSlots: freeSlotsCount,
        totalSlots: totalSlotsCount,
      };
    }
    return {
      freeSlots: 0,
      totalSlots: 0,
    };
  };

export const countFreeSlots = (slotUserTimzoneWise) => {
    let freeSlotCount = 0;

    for (const dateSlots of Object.values(slotUserTimzoneWise)) {
      freeSlotCount += dateSlots.reduce((count, slot) => count + slot.free, 0);
    }

    return freeSlotCount;
  };