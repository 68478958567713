import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Avatar } from "@material-ui/core";
import male_default from "../../../assets/images/male_default.png";
import err_fetch from "../../../assets/images/Frame 4907.png";
import err_switch from "../../../assets/images/Frame 4908.png";
import "./radio.css";
import { decrypt } from "../../../utils/other";
import CircularProgress from "@material-ui/core/CircularProgress";
import { encrypt } from "../../../utils/other";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../../services/api-services";
toast.configure();

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles((theme) => ({
  paper: { minWidth: "350px" },
  title: {
    color: "#333333",
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 600,
  },
  profileContainer: {
    display: "flex",
    padding: "2% 10% 5% 2%",
    minWidth: "300px",
    alignItems: "center",
    cursor: "pointer",
  },
  profileContainerInput: {
    marginRight: "15px",
    marginLeft: "15px",
    background: "#FF712D",
    cursor: "pointer",
  },
  profileContainerLabel: {
    marginRight: "15px",
    marginLeft: "15px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#464646",
  },
  btn: {
    background: "#FF712D",
    color: "white",
    borderRadius: "5px",
    width: "250px",
    "&:hover": {
      backgroundColor: "#FF712D",
      color: "white",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
  },
  errContainer: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#333333",
    marginTop: "20px",
    marginBottom: "20px",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const styles = useStyles();
  return (
    <MuiDialogTitle disableTypography className={`${classes.root}`} {...other}>
      <Typography variant="h6" className={styles.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Profile({
  showProfiles,
  setShowProfiles,
  profileData,
  loading,
  setLoading,
  errFetchUsers,
}) {
  const handleClose = () => {
    setShowProfiles(false);
  };
  const history = useHistory();
  const handleProfileChange = (profile) => {
    setSelectedProfile(profile);
  };
  const proceed = async () => {
    setLoading(true);
    API.post("/switchProfile", {
      type: 1,
      newProfile: selectedProfile.studentEmail,
    })
      .then((res) => {
        const encryptedUserData = encrypt(res.data);
        localStorage.setItem("user", encryptedUserData);
        const encryptedProfilePic = encrypt(res.data.profilePicUrl);
        localStorage.setItem("profile_pic", encryptedProfilePic);
      })
      .catch((e) => {
        setErrSwitch(true);
        console.log(e);
        setLoading(false);
        return;
      });
    setTimeout(() => {
      setLoading(false);
      localStorage.setItem("profileSwitched", true);
      window.open(process.env.REACT_APP_STUDENTPORTAL_BASE_URL, "_self");
      setShowProfiles(false);
    }, 1000);
  };
  const classes = useStyles();
  const studentData = decrypt(localStorage.getItem("user"));
  const [selectedProfile, setSelectedProfile] = useState(studentData);
  const [errSwitch, setErrSwitch] = useState(false);
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showProfiles}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Switch User
        </DialogTitle>
        <DialogContent>
          {errFetchUsers || errSwitch ? (
            <React.Fragment>
              <center>
                <img src={errFetchUsers ? err_fetch : err_switch} />
              </center>
              <div className={classes.errContainer}>
                {errFetchUsers ? "ERROR!" : "Server Error!"}
              </div>
              {errFetchUsers && (
                <div style={{ textAlign: "center" }}>
                  Unable to fetch user data to switch
                </div>
              )}
            </React.Fragment>
          ) : loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "300px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {profileData.map((profile) => {
                return (
                  <div
                    className={classes.profileContainer}
                    onClick={() => handleProfileChange(profile)}
                  >
                    <input
                      type="radio"
                      name="profile"
                      value={profile}
                      id={profile}
                      onChange={() => handleProfileChange(profile)}
                      className={classes.profileContainerInput}
                      checked={
                        selectedProfile?.studentEmail == profile?.studentEmail
                      }
                    />
                    <Avatar
                      src={profile.imageUrl ? profile.imageUrl : male_default}
                      style={{ height: "36px", width: "36px" }}
                      alt={profile.studentName}
                    />
                    <div
                      for={profile}
                      className={classes.profileContainerLabel}
                    >
                      {profile.studentName}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </DialogContent>
        {(errSwitch || errFetchUsers) && (
          <div className={classes.btnContainer}>
            <Button onClick={handleClose} className={classes.btn}>
              Close
            </Button>
          </div>
        )}
        {!loading && !errFetchUsers && !errSwitch && (
          <div className={classes.btnContainer}>
            <Button onClick={proceed} className={classes.btn}>
              Proceed
            </Button>
          </div>
        )}
      </Dialog>
    </div>
  );
}
