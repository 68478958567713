import React from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";

toast.configure();

const useStyles = makeStyles({
  bannerButton: {
    padding: "6px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "max-content",
    borderRadius: "4px",
    background: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
  },

  mobileBannerBtn: {
    border: "none",
    marginTop: "16px",
  },
});

const RequestMentorPopupButton = ({
  autoRescheduleGTM,
  setShowAlternateMentorModal,
}) => {
  const classes = useStyles();

  const alternateMentorModal = (type) => {
    if (type === "open") {
      setShowAlternateMentorModal(true);
    } else {
      setShowAlternateMentorModal(false);
    }
  };
  return (
    <button
      className={`${classes.bannerButton} ${
        window.ReactNativeWebView && classes.mobileBannerBtn
      }`}
      onClick={() => {
        autoRescheduleGTM("reschedule_alternate");
        alternateMentorModal("open");
      }}
    >
      <span
        style={{
          color: "#FF712D",
          fontFamily: "Poppins",
          fontSize: "12px",
        }}
      >
        Request Substitute Teacher {""}
        <span>
          <ArrowRightAltOutlinedIcon fontSize="medium" />
        </span>
      </span>
    </button>
  );
};

export default RequestMentorPopupButton;
