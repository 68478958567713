import React from "react";
import comms_success from "../../assets/images/illustration_1.svg";
import comms_chat from "../../assets/images/comm_chat.png";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "react-bootstrap";
import LOGO from "../../assets/images/CY_logo_full.svg";
import error404 from "../../assets/images/error_404.png";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Poppins",
  },
  doneHeading: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "28px",
    lineHeight: "42px",
    color: "#3D3D3D",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
  doneTxt: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#554E4E",
    textAlign: "center",
    marginTop: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  btnContainer: {
    border: "1px solid #25D366",
    color: "#25D366",
    background: "#FFFFFF",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "center",
    marginTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    "&:hover": {
      background: "#FFFFFF",
      color: "#25D366",
      border: "1px solid #25D366",
    },
  },
  reselectTxt: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#525252",
    textAlign: "center",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "18px",
    },
    width: "100%",
    padding: "0px 20px",
  },
  submitBtn: {
    padding: "4px 30px",
    color: "white",
    width: "156px",
    height: "40px",
    fontSize: "18px",
    background: "#FF712D",
    border: "1px solid #FF712D",
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FF712D",
      border: "1px solid #FF712D",
      color: "white",
    },
  },
  sbmitBtnBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
    paddingBottom: "30px",
  },
}));

const SuccessView = ({ error, setError }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {error ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "50px",
          }}
        >
          <img src={LOGO} alt="cy_logo" className={classes.cyLogo} />
          <img src={error404} alt="error" />
          <div className={classes.doneHeading}>We are sorry!!</div>
          <div className={classes.doneTxt}>
            Your request could not be completed. Please try again.
          </div>
          <div className={classes.sbmitBtnBox}>
            <Button
              onClick={() => setError(false)}
              className={classes.submitBtn}
            >
              Retry
            </Button>
          </div>
          <div className={classes.reselectTxt}>
            Reach out to us on our 24/7 Whatsapp channel to get your query
            resolved
          </div>
          <Button
            className={classes.btnContainer}
            onClick={() => window.open("https://wa.me/918884459977")}
          >
            Begin chat
            <img src={comms_chat} style={{ marginLeft: "5px" }} />
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "50px",
          }}
        >
          <img src={LOGO} alt="cy_logo" className={classes.cyLogo} />
          <img src={comms_success} alt="Success" />
          <div className={classes.doneHeading}>All Done!</div>
          <div className={classes.doneTxt}>
            Your class has been added successfully.
          </div>
          <div className={classes.reselectTxt}>
            We are available on Whatsapp in case you have any queries or
            concerns
          </div>
          <Button
            className={classes.btnContainer}
            onClick={() => window.open("https://wa.me/918884459977")}
          >
            Begin chat
            <img src={comms_chat} style={{ marginLeft: "5px" }} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default SuccessView;
