import { createSlice } from "@reduxjs/toolkit";

export const studentDataSlice = createSlice({
  name: "studentDetails",
  initialState: {
    profilePic: "",
    studentName: "",
  },
  reducers: {
    setStudentData: (state, action) => {
      const { profilePic, studentName } = action.payload;
      state.profilePic = profilePic;
      state.studentName = studentName;
    },
  },
});

export const { setStudentData } = studentDataSlice.actions;
export default studentDataSlice.reducer;
