import swal from "sweetalert";
import * as Sentry from "@sentry/react";
import { API } from "../services/api-services";
import { decrypt, encrypt } from "../utils/other";

const sessionExpired = (forceReload = false) => {
  const userObj = localStorage.getItem("user");
  const user = decrypt(userObj);
  const username = user.studentEmail;
  Sentry.captureMessage(`Session Expired:- ${username}`, "debug");
  swal({
    title: "Session Expired",
    text: "Continue working?",
    icon: "warning",
    buttons: ["No, go to home page.", "Yes, Login"],
    closeOnClickOutside: false,
    closeOnEsc: false,
  }).then((login) => {
    if (login) {
      swal({
        title: "Login",
        text: "Enter your password.",
        content: {
          element: "input",
          attributes: {
            placeholder: "Type your password",
            type: "password",
          },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
      })
        .then((password) => {
          return API.post("/login/student", {
            email: username,
            password: password,
            type: 1,
          });
        })
        .then((res) => {
          if (res) {
            const encryptedUserData = encrypt(res.data);
            localStorage.setItem("user", encryptedUserData);
            const encryptedProfilePic = encrypt(res.data.profilePicUrl);
            localStorage.setItem("profile_pic", encryptedProfilePic);
            window.location.reload();
          }
        })
        .catch((e) => {
          if (e.response?.data?.msg === "Email Or Password does not match") {
            localStorage.removeItem("user");
            window.location.replace("/");
          }
        });
    } else {
      localStorage.removeItem("user");
      window.location.replace("/");
    }
  });
};

export default sessionExpired;
