import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  studentInfoData: {},
  isFetched: false,
};

export const studentInfoReducer = createReducer(initialState, {
  INSERT_DATA: (state, action) => {
    state.studentInfoData = action.data;
  },
});
