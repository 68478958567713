import React from "react";

import welcome from "../src/assets/images/welcome.png";

const MobileStepsData = {
  steps: [
    {
      target: "body",
      content: (
        <React.Fragment>
          <img src={welcome} alt="welcome" width={200} height={150} />
          <br />
          <br />
          <h3>Student Portal</h3>
          <br />
          <span>
            You can join classes, track course progress, check studying
            resources and explore projects here.
          </span>
        </React.Fragment>
      ),
      placement: "center",
    },
    {
      target: "#smallHeader #resources",
      title: "Resources",
      content: "You can view Class Recordings and Learning Content here.",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
      placement: "auto",
    },
    {
      target: "#smallHeader #community",
      title: "Community",
      content:
        "Explore and interact with projects across all courses posted by students across the world.",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
      placement: "auto",
    },
    {
      target: "#smallHeader #referrals",
      title: "Referrals",
      content:
        "Invite your friends and family to Codeyoung and earn rewards & goodies.",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
      placement: "auto",
    },
    {
      target: "#profileDiv #profile",
      title: "Your Profile",
      content:
        "You can edit your information and change your display picture here.",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
      placement: "auto",
    },

    {
      target: "#todayClasses",
      title: "Today’s class",
      content: "Join classes from here on the day of your class.",
      placement: "right",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
    },
    {
      target: "#todayClasses  div div #changetimeDiv #changetime",
      title: "Change Timezones",
      content:
        "View your class timings on different timezones by changing it here.",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
    },
    {
      target: "#upcommingClasses",
      title: "Future classes",
      content: "View your next 2 upcoming classes here.",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
    },
    {
      target: "#upcommingClasses div div #changetimeDiv #changetime",
      title: "Change Timezones",
      content:
        "View your class timings on different timezones by changing it here.",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
    },
    {
      target: "#bar div #card_div #barDiv",
      title: "View course progress",
      content: "Track your current ongoing course progress here.",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
      placement: "auto",
    },
    {
      target: "#project div div div button ",
      title: "Add your projects",
      content:
        "Publish your projects to our community and let other students view and vote your projects.",
      styles: {
        //this styles override the styles in the props
        tooltipContainer: {
          textAlign: "left",
        },
      },
      placement: "bottom",
    },
  ],
  disableBeacon: false,
};

export default MobileStepsData;
