import Axios from "axios";
import sessionExpired from "../Core/sessionExpired";
import { decrypt, encrypt } from "../utils/other";
// import { BASE_URL } from "./../Core/constants";

const BASE_URL = process.env.REACT_APP_STUDENTLOGIN_BASE_URL;
const EPICENTER_BASE_URL = process.env.REACT_APP_EPICENTERAPI_BASE_URL;
const api = function (baseUrl) {
  this.apiBase = baseUrl;
  const accesToken = decrypt(localStorage.getItem("user"));
  const appVersion = localStorage.getItem("appVersion");
  this.accesToken = accesToken ? accesToken.ACCESS_TOKEN : "";
  this.refreshToken = accesToken ? accesToken.REFRESH_TOKEN : "";
  this.axios = Axios.create({
    baseURL: this.apiBase,
    timeout: 100000000,
    //#region [comment]
    // onUploadProgress ( p ) {
    //     const progress = p.loaded / p.total;

    //     // check if we already displayed a toast
    //     if ( toastId === null ) {
    //         toastId = toast( 'Upload in Progress', {
    //             progress: progress
    //         } );
    //     } else {
    //         toast.update( toastId, {
    //             progress: progress
    //         } );
    //     }
    // },
    // onDownloadProgress ( p ) {
    //     const progress = p.loaded / p.total;

    //     // check if we already displayed a toast
    //     if ( toastId === null ) {
    //         toastId = toast( 'Download in Progress', {
    //             progress: progress
    //         } );
    //     } else {
    //         toast.update( toastId, {
    //             progress: progress
    //         } );
    //     }
    // }
    //#endregion
  });

  let isRefreshing = false;
  let refreshApis = [];

  const storeExpiredApis = (cb) => {
    refreshApis.push(cb);
  };

  const onRefreshed = (token) => {
    refreshApis.map((cb) => cb(token));
  };

  this.axios.defaults.headers.post["Content-Type"] = "application/json";
  this.axios.interceptors.request.use(
    async (req) => {
      const tokenObj = decrypt(localStorage.getItem("user"));
      req["headers"]["Authorization"] = `Bearer ${tokenObj.ACCESS_TOKEN}`;
      req["headers"]["refresh-token"] = tokenObj.REFRESH_TOKEN;
      if (window.ReactNativeWebView && appVersion) {
        req["headers"]["auth-source"] = "cy-sso";
      }
      return req;
    },
    (e) => {
      return Promise.reject(e);
    }
  );
  this.axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (e) => {
      const originalRequest = e.config;
      if (
        e.response &&
        e.response.status === 401 &&
        e.response.data.msg === "Invalid Authorization" &&
        !originalRequest._retry
      ) {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) &&
          window.ReactNativeWebView
        ) {
          if (isRefreshing) {
            return new Promise((resolve) => {
              storeExpiredApis((token) => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                resolve(this.axios(originalRequest));
              });
            });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          try {
            const tokenObj = decrypt(localStorage.getItem("user"));
            const response = await Axios.post(
              `${process.env.REACT_APP_SSO_BASE_URL}api/login/validate/refresh`,
              {
                accessToken: tokenObj.ACCESS_TOKEN,
                refreshToken: tokenObj.REFRESH_TOKEN,
              }
            );
            const { accessToken, refreshToken } = response.data;
            console.log(response.data, "validate response");
            console.log(tokenObj.accessToken, "new one");
            console.log(tokenObj.refreshToken, "new one refresh");
            const authObj = {
              ACCESS_TOKEN: accessToken,
              REFRESH_TOKEN: refreshToken,
            };
            const encryptedUserData = encrypt(authObj);
            await localStorage.setItem("user", encryptedUserData);
            originalRequest["headers"][
              "Authorization"
            ] = `Bearer ${accessToken}`;
            onRefreshed(accessToken);
            originalRequest["headers"]["refresh-token"] = refreshToken;
            return this.axios(originalRequest);
          } catch (refreshError) {
            console.error("Token refresh failed:", refreshError);
            if (window.ReactNativeWebView) {
              const mobileData = {
                type: "route",
              };
              window.ReactNativeWebView.postMessage(JSON.stringify(mobileData));
            }
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
            refreshApis = [];
          }
        } else {
          sessionExpired();
        }
      }
      return Promise.reject(e);
    }
  );
};

api.prototype.getApiBase = function () {
  return this.apiBase;
};

api.prototype.get = function (url, params = {}, data = {}) {
  return this.axios({
    method: "get",
    url,
    params,
    data,
  });
};

api.prototype.post = function (url, data = {}, params = {}) {
  return this.axios({
    method: "post",
    url,
    data,
    params,
  });
};

api.prototype.put = function (url, data, params = {}) {
  return this.axios({
    method: "put",
    url,
    data,
    params,
  });
};

api.prototype.delete = function (url, data) {
  return this.axios({
    method: "delete",
    url,
    data,
  });
};

const API = new api(BASE_URL);
const API_EPICENTER = new api(EPICENTER_BASE_URL);

export { API, API_EPICENTER, api };
