import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { API } from "../../../services/api-services";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CodeyoungLogo from "../../../assets/images/CY_logo.svg";
import CourseImg from "../../../assets/images/date.png";
import Dates from "../../../assets/images/course.png";
import Mentor from "../../../assets/images/mentor1.png";
import DownloadIcon from "@material-ui/icons/GetAppOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";
import TagManager from "react-gtm-module";
toast.configure();

const useStyles = makeStyles((theme) => ({
  layout: {},
  headerLogo: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
  },
  codeyoungLogo: {
    height: "50px",
    width: "150px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0px 20px",
      marginTop: "60px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "75%",
      margin: "40px auto 0px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
      margin: "40px auto 0px",
    },
  },
  videoContainer: {},
  contentLayout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  titleLayout: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "16px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 0px",
    },
  },
  title: {
    color: "#666666",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "600",
    fontFamily: "Inter",
  },
  downloadBtn: {
    color: "#FF712D",
    textTransform: "capitalize",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "700",
    fontFamily: "Inter",
  },
  detailsLayout: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: "1",
    },
  },
  detailsWrapper: {
    marginBottom: "19px",
  },
  detailsImg: {
    height: "20px",
    width: "20px",
    marginRight: "7px",
  },

  details: {
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#666666",
    fontWeight: "600",
  },

  errorMsg: {
    display: "flex",
    justifyContent: "center",
    color: "red",
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

const ViewRecording = (props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(null);
  const [link, setLink] = useState("");
  const isBigScreen = useMediaQuery("(min-width:600px)");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const batchId = queryParams.get("batchId");
  const scheduleId = queryParams.get("scheduleId");

  const convertedTime = (response) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const time = moment(response)
      .subtract(330, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    const newTime = moment.utc(time.replace(/ /g, "T")).tz(timeZone);
    const formattedDate = newTime
      .format("YYYY-MM-DD HH:mm:ss")
      .replace(/T/, " ")
      .replace(/\..+/, "");

    const datePart = new Date(formattedDate)
      .toLocaleString("en-US", {
        weekday: "short",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");

    const timePart = new Date(formattedDate).toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    return `${datePart} ${timePart}`;
  };

  useEffect(() => {
    API.get("/recording", {
      batchId: batchId,
      scheduleId: scheduleId,
    })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((e) => {
        toast.error("Error while requesting the recording", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error("Error while requesting the recording", e);
      });
  }, []);

  useEffect(() => {
    captureRecordingGTM("recording_page_loaded");
  }, []);
  const captureRecordingGTM = (event_action) => {
    const tagManagerArgs = {
      gtmId: "GTM-TH4RWGG",
      dataLayer: {
        event: "codeyoung_portal",
        event_category: "codeyoung_portal",
        event_action,
        event_label: "",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    TagManager.initialize(tagManagerArgs);
  };
  const handleDownload = () => {
    const anchor = document.createElement("a");
    anchor.href = response?.link;
    anchor.download = "video.mp4";
    anchor.click();
    captureRecordingGTM("recording_downloaded");
  };

  return (
    <div className={classes.layout}>
      <div className={classes.headerLogo}>
        <img
          src={CodeyoungLogo}
          alt="codeyoung_logo"
          className={classes.codeyoungLogo}
        />
      </div>
      <div className={classes.container}>
        {(response?.status === "Standard" ||
          response?.status === "Restoration Done") &&
        response?.link !== null ? (
          <div item className={classes.videoContainer}>
            <video width="100%" height="100%" src={response?.link} controls />
          </div>
        ) : response?.status === "Glacier" ? (
          <div className={classes.errorMsg}>
            {" "}
            Recording expired, please request recording from Student portal
            again if required.
          </div>
        ) : response?.status === "Under Restoration" ? (
          <div className={classes.errorMsg}>
            Retrieval in process please try again after 1 hour
          </div>
        ) : (
          <></>
        )}
        <div item className={classes.titleLayout}>
          <div className={classes.title}>{response?.topicName}</div>
          <div>
            {response?.status === "Standard" ||
            response?.status === "Restoration Done" ? (
              isBigScreen ? (
                <Button
                  variant="text"
                  endIcon={<DownloadIcon />}
                  className={classes.downloadBtn}
                  onClick={handleDownload}
                >
                  Download
                </Button>
              ) : (
                <IconButton
                  aria-label="download"
                  className={classes.downloadBtn}
                  onClick={handleDownload}
                >
                  <DownloadIcon />
                </IconButton>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <div item className={classes.detailsLayout}>
          <div className={classes.detailsWrapper}>
            <img src={Dates} alt="date" className={classes.detailsImg} />
            <span className={classes.details}>
              {response?.startTimeIst && convertedTime(response?.startTimeIst)}
            </span>
          </div>
          <div className={classes.detailsWrapper}>
            <img src={CourseImg} alt="course" className={classes.detailsImg} />
            <span className={classes.details}>{response?.courseName}</span>
          </div>
          <div className={classes.detailsWrapper}>
            <img src={Mentor} alt="mentor" className={classes.detailsImg} />
            <span className={classes.details}>{response?.mentorName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRecording;
