import { createSlice } from "@reduxjs/toolkit";

export const fcmSlice = createSlice({
  name: "fcmDetails",
  initialState: {
    tokenFcm: "",
  },
  reducers: {
    setFcmToken: (state, action) => {
      state.tokenFcm = action.payload;
    },
  },
});

export const { setFcmToken } = fcmSlice.actions;
export default fcmSlice.reducer;
