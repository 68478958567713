import { jwtDecode } from "jwt-decode";
import { decrypt } from "./other";

export const auth = () => !!decrypt(localStorage.user);
export const getUser = () => {
  const user = decrypt(localStorage.user);
  if (Object.keys(user).length > 0) {
    const userId = jwtDecode(user?.ACCESS_TOKEN);
    return userId.user;
  } else {
    return {};
  }
};
