import { createSlice } from "@reduxjs/toolkit";

const componentSlice = createSlice({
  name: "currentComponent",
  initialState: [],
  reducers: {
    setCurrentComponent: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCurrentComponent } = componentSlice.actions;
export default componentSlice.reducer;
